import _map from 'lodash/map.js'
import _find from 'lodash/find.js'
import _pick from 'lodash/pick.js'
import Bugsnag from '@bugsnag/js'
import dayjs from 'dayjs'
import { Enums, EnumsLegacy } from '~/config/Enums.js'

function convertServerPickup(raw) {
    const pickup = {
        id: raw.pickupId,
        status: EnumsLegacy.pickupStatus[raw.status],
        statusRaw: raw.status,
        tourId: raw.tourId,
        date: new Date(raw.date),
        contractId: raw.contractId,
        parcelPickup: raw.parcelPickup,
        parcelOption: raw.parcelOption,
        carrier: raw.carrier,
    }

    return pickup
}

function convertServerContract(data) {
    const { contract, subscription, subroutes, cancellation } = data

    const converted = {}
    try {
        converted.pickupAddress = convertServerAddress(contract)
        converted.contact = _pick(contract, ['email', 'phone'])

        converted.id = contract.id
        converted.status = !!contract.status
        converted.sendReminder = !!contract.sendReminders

        converted.type = contract.subscriptionId
            ? Enums.orderTypes.regularly
            : Enums.orderTypes.once

        if (converted.type === Enums.orderTypes.regularly) {
            converted.minimumTerm = contract.period
        } else {
            converted.minimumTerm = false
        }

        converted.startDate = new Date(contract.firstPickup)

        converted.subscription = false
        if (converted.type === Enums.orderTypes.regularly) {
            converted.subscription = {}
            converted.subscription.id = subscription.id
            // converted.subscription.segment = subscription.segment
            converted.subscription.frequency =
                EnumsLegacy.frequencies._mapping[subscription.frequency]
            converted.subscription.recyclables =
                EnumsLegacy.recyclables[subscription.recyclables]
            converted.subscription.pricePerMonth = parseFloat(
                subscription['price' + contract.period],
            )

            converted.products = _map(subscription?.products ?? [], (p) => ({
                id: p.id,
                name: p.name,
                amount: p.number,
                recyclables: EnumsLegacy.recyclables[p.recyclables],
            }))

            converted.tourType =
                converted.subscription.recyclables === Enums.recyclables.clothes
                    ? Enums.tourTypes['2ndhandgoods']
                    : Enums.tourTypes.recyclables
        } else {
            converted.products = _map(contract?.products ?? [], (p) => ({
                id: p.id,
                name: p.name,
                amount: p.amount,
                recyclables: EnumsLegacy.recyclables[p.recyclables],
                unit: p.unit,
            }))

            converted.tourType = _find(
                converted.products,
                (p) => p.recyclables === Enums.recyclables.clothes,
            )
                ? Enums.tourTypes['2ndhandgoods']
                : Enums.tourTypes.recyclables

            converted.price = contract.price || null
        }

        converted.cancellation = {
            actualDate:
                cancellation.actualDate && new Date(cancellation.actualDate),
            possibleDate:
                cancellation.possibleDate &&
                new Date(cancellation.possibleDate),
        }

        converted.subroutes = _map(subroutes, (subroute) => {
            return {
                id: subroute.id,
                routeId: subroute.routeId,
                subrouteId: subroute.subrouteId,
                order: subroute.order,
                timeslot: subroute.timeframeText,
                dayIndex: subroute.weekday,
                status: !!subroute.status,
                tourType: EnumsLegacy.tourTypes._mapping[subroute.type],
            }
        })
    } catch (error) {
        console.warn('server contract conversion failed', error)

        Bugsnag.notify(error, (event) => {
            event.context = 'server-tools.contract-conversion'
        })
    }

    return converted
}

function convertServerInvoice(data) {
    const converted = {}

    converted.id = data.invoiceId
    converted.date = data.date ? new Date(data.date) : null
    converted.payactiveId = data.payactiveInvoiceId
    converted.status = data.invoiceStatusText
    converted.total = data.invoiceTotal
    converted.termInDays = data.invoiceTermInDays
    converted.dueDate = dayjs(converted.date)
        .add(converted.termInDays, 'days')
        .toDate()

    return converted
}

function convertServerData(data) {
    const converted = {}

    if (data.email) {
        converted.email = data.email
    }

    if (data.customerId) {
        converted.customerId = data.customerId
    }

    if (data.invoiceAddress) {
        converted.invoiceAddress = convertServerAddress(data.invoiceAddress)
    }

    if (data.contact) {
        converted.contact = _pick(data.contact, ['email', 'phone'])
    }

    return converted
}

function convertClientData(data) {
    const converted = {}

    if (data.invoiceAddress) {
        converted.invoiceAddress = convertClientAddress(data.invoiceAddress)
    }

    if (data.contact) {
        converted.contact = convertClientContact(data.contact)
    }

    if (data.account) {
        converted.account = _pick(data.account, ['passwordOld', 'passwordNew'])
    }

    return converted
}

function convertClientAddress(address) {
    let converted = {}

    if (!address) {
        return converted
    }

    converted = _pick(address, [
        'firstName',
        'lastName',
        'organization',
        'street',
        'streetNumber',
        'floor',
        'zipCode',
        'city',
    ])
    converted.additionalAdressInfo = address.additionalInfo

    return converted
}

function convertClientContact(contact) {
    let converted = {}

    if (!contact) {
        return converted
    }

    converted = _pick(contact, ['email', 'phone'])

    return converted
}

function convertServerAddress(address) {
    let converted = {}

    if (!address) {
        return converted
    }

    converted = _pick(address, [
        'firstName',
        'lastName',
        'organization',
        'street',
        'streetNumber',
        'floor',
        'zipCode',
        'city',
    ])
    converted.additionalInfo = address.additionalAdressInfo

    return converted
}

export function useServerTools() {
    return {
        convertServerData,
        convertServerAddress,
        convertServerContract,
        convertServerPickup,
        convertServerInvoice,
        convertClientData,
        convertClientAddress,
        convertClientContact,
    }
}
